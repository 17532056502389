<template>
  <div>
    <!-- 确认支付界面 -->
    <el-dialog
      title="支付记录"
      top="20vh"
      width="85%"
      :visible.sync="is_show_in_Page"
    >
		<div class="tab_height">
			<el-table :data="list" :border="true" :stripe="true" size="small"  v-loading="list.loading">
				<el-table-column label="支付编号/运单编号" width="210px">
					<template slot-scope="scope">
						<div class="tab_line_item">支付: {{scope.row.ra_num}}</div>
						<div class="tab_line_item">运单: {{scope.row.truck_tord_num}}</div>
						<div class="tab_line_item">发起: {{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="装卸货地信息">
					<template slot-scope="scope">
						<div class="tab_line_item">(省市区) {{scope.row.tord_info.case_prov}} / {{scope.row.tord_info.case_city}} / {{scope.row.tord_info.case_county}}</div>
						<div class="tab_line_item">(详&nbsp;&nbsp;&nbsp;细) {{scope.row.tord_info.case_addr?scope.row.tord_info.case_addr:'无'}}</div>
						<div class="tab_line_item">(省市区) {{scope.row.tord_info.aim_prov}} / {{scope.row.tord_info.aim_city}} / {{scope.row.tord_info.aim_county}}</div>
						<div class="tab_line_item">(详&nbsp;&nbsp;&nbsp;细) {{scope.row.tord_info.aim_addr?scope.row.tord_info.aim_addr:'无'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="货主信息" width="160px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.shipper_info.name}} / {{scope.row.shipper_info.tel}}</div>
						<div class="tab_line_item">{{scope.row.shipper_info.company_name}}</div>
					</template>
				</el-table-column>
				<el-table-column label="车主/司机" width="230px">
					<template slot-scope="scope">
						<div class="tab_line_item">车主: {{scope.row.truck_owner_info.name}} / {{scope.row.truck_owner_info.tel}}</div>
						<div class="tab_line_item">司机: {{scope.row.driver_info.name}} / {{scope.row.driver_info.tel}}</div>
						<div class="tab_line_item">货车: {{scope.row.tord_info.truck_plate_num}}</div>
            <div class="tab_line_item" v-if="scope.row.driver_info.card_user_name">收款人姓名: {{scope.row.driver_info.card_user_name}}</div>
						<div class="tab_line_item" v-if="scope.row.driver_info.card_num">收款人卡号: {{scope.row.driver_info.card_num}}</div>
						<div class="tab_line_item" v-if="scope.row.driver_info.is_owner">收款人性质: {{scope.row.driver_info.is_owner == '1'?"司机":"代收人" }}</div>
					</template>
				</el-table-column>
				<el-table-column label="支付描述" width="125px">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.this_pay_type_text}} / {{scope.row.this_pay_is_last_text}}</div>
						<div class="tab_line_item">备注: {{scope.row.mark?scope.row.mark:"无"}}</div>
					</template>
				</el-table-column>
				<el-table-column label="服务费" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">本次付: {{scope.row.this_pay_freight}}元</div>
						<div class="tab_line_item">费率: {{scope.row.extra_service_charge_rate}} / {{scope.row.extra_service_charge_type_text}}</div>
						<div class="tab_line_item">服务费: {{scope.row.extra_service_charge_total}}元</div>
					</template>
				</el-table-column>
				<el-table-column label="支付状态" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">金额: {{scope.row.this_payed}}元</div>
						<div class="tab_line_item">{{scope.row.pay_status_text}}</div>
						<div class="tab_line_item">{{scope.row.payed_time_text}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="发票状态" width="140px">
					<template slot-scope="scope">
						<div class="tab_line_item">金额: {{scope.row.this_invoiced}}元</div>
						<div class="tab_line_item">{{scope.row.invoice_status_text}}</div>
						<div class="tab_line_item">{{scope.row.invoiced_time_text}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="打款状态" width="170px">
					<template slot-scope="scope">
						 <div>{{scope.row.settle_status_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="结算状态" width="170px">
					<template slot-scope="scope">
						<div class="tab_line_item">金额: {{scope.row.this_settled}}元 / {{scope.row.this_pay_type_text}}</div>
						<div class="tab_line_item">
							{{scope.row.settle_status_text}}
						</div>
						<div class="tab_line_item">{{scope.row.settled_time_text}}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    truck_tord_num: String,
    is_show: Number, //是否显示
  },
  data() {
    return {
      //列表是否显示
      is_show_in_Page: false,
      //列表数据
      list: [],
      form: {
        truck_tord_num: "",
      },
    };
  },
  created() {},
  mounted() {},
  watch: {
    is_show(new_data) {
      if (new_data) {
        this.is_show_in_Page = true;
        this.get_page_data();
      }
    },
  },
  methods: {
    get_page_data() {
      this.form.truck_tord_num = this.truck_tord_num;
      console.log(this.form);
      //读取数据
      this.$my.net.req({
        data: {
          mod: "truck_tord_real_time",
          ctr: "tord_payed_list_by_admin",
          ...this.form,
        },
        callback: (data) => {
          console.log(data,'支付数据');
          //加载完毕
          // this.list.loading = false;

          //总数
          // this.list.total = data.max;

          //预处理
          for (let item of data.list) {
            //数据空值初始化
            item.truck_info = item.truck_info ? item.truck_info : {};
            item.driver_info = item.driver_info ? item.driver_info : {};
            item.truck_owner_info = item.truck_owner_info
              ? item.truck_owner_info
              : {};
            item.shipper_info = item.shipper_info ? item.shipper_info : {};

            //成交时间
            item.creat_time_text = this.$my.other.totime(item.creat_time);

            //本次支付类型(1:现金,2:燃油费,3:过路费)
            switch (item.this_pay_type) {
              case "1":
                item.this_pay_type_text = "现金";
                break;
              case "2":
                item.this_pay_type_text = "燃油费";
                break;
              case "3":
                item.this_pay_type_text = "过路费";
                break;
            }

            //是否是最后一笔支付(1:是,2:不是)
            switch (item.this_pay_is_last) {
              case "1":
                item.this_pay_is_last_text = "最后一笔";
                break;
              case "2":
                item.this_pay_is_last_text = "非最后一笔";
                break;
            }

            //额外服务费计算方式(1:除法模式,2:乘法模式)
            switch (item.extra_service_charge_type) {
              case "1":
                item.extra_service_charge_type_text = "除法模式";
                break;
              case "2":
                item.extra_service_charge_type_text = "乘法模式";
                break;
            }

            //支付状态(1:待审核,2:支付完成,3:支付失败/打回)
            switch (item.pay_status) {
              case "1":
                item.pay_status_text = "待审核";
                break;
              case "2":
                item.pay_status_text = "支付完成";
                break;
              case "3":
                item.pay_status_text = "支付失败";
                break;
            }

            //完成/打回 时间
            if (item.pay_status != 1) {
              item.payed_time_text = this.$my.other.totime(item.payed_time);
            } else item.payed_time_text = "";

            //开票状态(1:未申请,2:申请中,3:已开出,4:已驳回)
            switch (item.invoice_status) {
              case "1":
                item.invoice_status_text = "未申请";
                break;
              case "2":
                item.invoice_status_text = "申请中";
                break;
              case "3":
                item.invoice_status_text = "已开出";
                break;
              case "4":
                item.invoice_status_text = "已驳回";
                break;
            }

            //开票完成时间
            if (["3", "4"].indexOf(item.invoice_status) != -1) {
              item.invoiced_time_text = this.$my.other.totime(
                item.invoiced_time
              );
            } else item.invoiced_time_text = "";

            //结算给车主的状态(1:未申请,2:待打款,3:已打款,4:结算失败)
            switch (item.settle_status) {
              case "1":
                item.settle_status_text = "未申请";
                break;
              case "2":
                item.settle_status_text = "待打款";
                break;
              case "3":
                item.settle_status_text = "已打款";
                break;
              case "4":
                item.settle_status_text = "打款失败";
                break;
            }

            //结算完成时间
            if (["3", "4"].indexOf(item.settle_status) != -1) {
              item.settled_time_text = this.$my.other.totime(item.settled_time);
            } else item.settled_time_text = "";
          }

          //渲染
             this.list = data.list;
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button--primary {
  margin-top: 20px;
}
#dialog {
  margin-top: 20vh;
}
.tab_height {
  width: 100%;
}
#button {
  margin-left: 0;
}
</style>