<template>
  <div class="app-container">
    <div id="container" style="height: 95vh"></div>
    <div id="panel"></div>
  </div>
</template>

<script>
import myStore from "../tord/store.vue";
export default {
  name: "index",
  props: {
    start: "",
    end: "",
    start_province: "",
    end_province: "",
    num:'',
  },
  data() {
    return {};
  },
  mounted() {
    //调用地图初始化方法
    this.map_see();
  },
  watch: {
    num(){
      this.map_see()
    }
    // start() {
    //   this.map_see();
    //   return
    // },
    // end() {
    //   this.map_see();
    //    return
    // },
    // end_province() {
    //   this.map_see();
    //    return
    // },
    // start_province() {
    //   this.map_see();
    //    return
    // },
  },
  methods: {
    map_see() {
      var start = this.start
      var end = this.end
      var start_province = this.start_province
      var end_province = this.end_province
      // 地图初始化
      let map = new AMap.Map("container", {
        zoom: 11,
        resizeEnable: true,
      });
      var driving = new AMap.Driving({
        map: map,
      });
      // 根据起终点名称规划驾车导航路线
      driving.search(
        [
          { keyword: start, city: start_province },
          { keyword: end, city: end_province },
        ],
        function (status, result) {
          console.log(status,result);
        }
      );
    },
  },
};
</script>

<style scoped>
.info {
  line-height: 30px;
  font-size: 12px;
}
</style>